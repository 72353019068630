<template>
  <b-card-code :title="titleForm">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nama Kavling"
              v-if="this.lastPathParams === `form`"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Kavling"
                rules="required"
              >
                <v-select
                  v-model="formSubmit.kavling_id"
                  :options="kavlingOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(_id) => _id._id"
                  placeholder="Pilih Kavling"
                  @input="pilihKavling($event)"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Kavling Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Nama Kavling"
              v-if="this.lastPathParams != `form`"
            >
              <b-form-input
                v-model="formDetail.kavling_name"
                placeholder="Nama Kavling"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nama Blok"
              v-if="this.lastPathParams === `form`"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Blok"
                rules="required"
              >
                <v-select
                  v-model="formSubmit.block_id_list"
                  :options="blokOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(_id) => _id._id"
                  placeholder="Pilih Blok"
                  multiple
                  @input="pilihBlok($event)"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Blok Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Nama Blok"
              v-if="this.lastPathParams != `form`"
            >
              <b-form-input
                v-model="formDetail.block_name"
                placeholder="Nama Blok"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Pembelian">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Pembelian"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.purchase_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Pembelian"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Pembelian Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tanggal Jatuh Tempo">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Jatuh Tempo"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.due_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Jatuh Tempo"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Jatuh Tempo Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="6">
            <b-form-group label="Jumlah Angsuran">
              <validation-provider
                #default="{ errors }"
                name="Jumlah Angsuran"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.installment_count"
                  placeholder="Jumlah Angsuran"
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah Angsuran Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <b-form-group label="Cash DP">
              <validation-provider
                #default="{ errors }"
                name="Cash DP"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.down_payment_amount"
                  placeholder="Cash DP"
                  @input="handleInputHarga"
                />
                <small class="text-danger">{{
                  errors[0] ? "Cash DP Wajib Diisi" : ""
                }}</small>

                <small v-if="formSubmit.down_payment_amount">
                  Rp. {{ price2 }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Harga">
              <b-form-input
                placeholder="Harga"
                @input="handleInputPrice"
                v-model="formSubmit.total_price"
                disabled
              />
              <small v-if="formSubmit.total_price">
                  Rp. {{ totalPrice2 }}
              </small>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Nama Pembeli">
              <validation-provider
                #default="{ errors }"
                name="Nama Pembeli"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.name"
                  placeholder="Nama Pembeli"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Pembeli Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label="Fasilitas">
              <validation-provider
                #default="{ errors }"
                name="Fasilitas"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.facility"
                  placeholder="Fasilitas"
                />
                <small class="text-danger">{{
                  errors[0] ? "Fasilitas Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <b-form-group label="NIK">
              <validation-provider
                #default="{ errors }"
                name="NIK"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.identity_number"
                  placeholder="NIK"
                />
                <small class="text-danger">{{
                  errors[0] ? "NIK Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nama Marketing">
              <validation-provider
                #default="{ errors }"
                name="Nama Marketing"
                rules="required"
              >
                <v-select
                  v-model="formSubmit.marketing_id"
                  :options="marketingOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(_id) => _id._id"
                  placeholder="Pilih Marketing"
                  @input="pilihMarketing($event)"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Marketing Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Alamat Pembeli">
              <validation-provider
                #default="{ errors }"
                name="Alamat Pembeli"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.address"
                  placeholder="Alamat Pembeli"
                />
                <small class="text-danger">{{
                  errors[0] ? "Alamat Pembeli Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nomor Telepon">
              <validation-provider
                #default="{ errors }"
                name="Nomor Telepon"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.phone"
                  placeholder="Nomor Telepon"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nomor Telepon Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tempat Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.place_of_birth"
                  placeholder="Tempat Lahir"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tempat Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tanggal Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Lahir"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.date_of_birth"
                  class="form-control"
                  placeholder="Pilih Tanggal Lahir"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Pekerjaan">
              <validation-provider
                #default="{ errors }"
                name="Pekerjaan"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.profession"
                  placeholder="Pekerjaan"
                />
                <small class="text-danger">{{
                  errors[0] ? "Pekerjaan Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Kewarganegaraan">
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.nationality"
                  placeholder="Kewarganegaraan"
                />
                <small class="text-danger">{{
                  errors[0] ? "Kewarganegaraan Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Status">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  v-model="formSubmit.marital_status"
                  :options="statusOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="Pilih Status"
                  :reduce="(_id) => _id._id"
                />
                <small class="text-danger">{{
                  errors[0] ? "Status Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formSubmit.note"
                placeholder="Masukkan Catatan"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- table angsuran hanya saat edit data saja -->

        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> Detail Angsuran </b></b-col
          >

          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2">
            <b-button variant="success" @click="showModalTambah()">
              Tambah Angsuran
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'payment_name'">
                  {{ props.row.payment_name }}
                </span>

                <span v-if="props.column.field === 'note'">
                  {{ props.row.note }}
                </span>

                <span v-if="props.column.field === 'payment_date'">
                  {{ props.row.payment_date.substring(0, 10) }}
                </span>

                <span v-if="props.column.field === 'payment_amount'">
                  Rp.
                  {{
                    props.row.payment_amount
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span v-if="props.row.payment_name === `DP Cash`"> - </span>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-tooltip.hover.v-warning
                    title="Ubah"
                    class="btn-icon mr-2"
                    @click="showModalUbah(props)"
                    v-if="props.row.payment_name != `DP Cash`"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    v-b-tooltip.hover.v-danger
                    title="Hapus"
                    class="btn-icon mr-2"
                    @click="deleteInfo(props)"
                    v-if="props.row.payment_name != `DP Cash`"
                  >
                    <feather-icon icon="Trash2Icon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Ubah"
                    class="btn-icon"
                    @click="downloadKwitansi(props)"
                  >
                    <feather-icon icon="ArrowDownIcon" class="cursor-pointer" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>

        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2">
            Total Masuk: Rp.
            {{
              this.total_paid
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </b-col>

          <b-col cols="3" align="end" class="mt-2">
            Kekurangan: Rp.
            {{
              this.total_unpaid
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </b-col>
        </b-row>

        <!-- end table angsuran -->

        <!-- button -->

        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitValidate"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- modal form angsuran -->
    <b-modal
      id="modal-detail-angsuran"
      ref="ref-form-angsuran"
      title="Form Data Angsuran"
      hide-footer="true"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRulesAngsuran">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label-for="price">
                <template v-slot:label> Nama Kavling </template>
                <b-form-input id="price" v-model="kavling_name" disabled />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="price">
                <template v-slot:label> Nama Blok </template>
                <b-form-input id="price" v-model="block_name" disabled />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="price">
                <template v-slot:label> Nama Pembeli </template>
                <b-form-input id="price" v-model="formSubmit.name" disabled />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="price">
                <template v-slot:label> Harga </template>
                <b-form-input
                  id="price"
                  v-model="formSubmit.total_price"
                  disabled
                />
                <small v-if="formSubmit.total_price">
                  Rp. {{ totalPrice2 }}
                </small>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="price">
                <template v-slot:label> Kekurangan </template>
                <b-form-input id="price" v-model="total_unpaid" disabled />
                <small v-if="formSubmit.total_price">
                  Rp. {{
                    this.total_unpaid
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </small>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tanggal Pembayaran <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Pembayaran"
                  rules="required"
                >
                  <flat-pickr
                    v-model="formPaymentHistory.payment_date"
                    class="form-control"
                    placeholder="Pilih Tanggal Pembayaran"
                    :config="configs.allowInput"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Tanggal Pembayaran Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Nominal <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Nominal"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formPaymentHistory.payment_amount"
                    placeholder="Masukkan Nominal"
                    @input="handleInputPayment"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Nominal Wajib Diisi" : ""
                  }}</small>
                  <small v-if="formPaymentHistory.payment_amount">
                    Rp. {{ paymentAmount2 }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Catatan" label-for="mc-country">
                <b-form-textarea
                  rows="3"
                  v-model="formPaymentHistory.note"
                  placeholder="Masukkan Catatan"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-row class="mt-5 mb-2">
          <b-col align="end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="kembaliModalForm()"
              class="mr-2"
            >
              Batal
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitFormAngsuran()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <!-- end modal form angsuran -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      price: "",
      price2: "",
      totalPrice2: "",
      paymentAmount2: "",

      kavlingOptions: [],
      blokOptions: [],
      marketingOptions: [],

      statusOptions: [
        {
          _id: "KAWIN",
          name: "KAWIN",
        },
        {
          _id: "BELUM_KAWIN",
          name: "BELUM KAWIN",
        },
        {
          _id: "JANDA",
          name: "JANDA",
        },
        {
          _id: "DUDA",
          name: "DUDA",
        },
      ],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      titleForm: "",

      lastPathParams: "",

      formSubmit: {
        kavling_id: "",
        block_id_list: [],
        purchase_date: "",
        due_date: "",
        down_payment_amount: 0,
        name: "",
        phone: "",
        address: "",
        identity_number: "",
        nationality: "",
        profession: "",
        marital_status: "",
        date_of_birth: "",
        place_of_birth: "",
        note: "",
        marketing_id: "",
        total_price: "",
      },

      formDetail: {
        kavling_name: "",
        block_name: "",
      },

      id_ownership: "",
      block_name: "",
      kavling_name: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",

      total_paid: "",
      total_unpaid: "",

      rows: [],
      columns: [
        {
          label: "Item",
          field: "payment_name",
          thClass: "text-center",
        },
        {
          label: "Tanggal Pembayaran",
          field: "payment_date",
          thClass: "text-center",
        },
        {
          label: "Nominal",
          field: "payment_amount",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],

      formDisabledPayment: {},

      formPaymentHistory: {
        payment_amount: "",
        payment_date: "",
        note: "",
      },

      aksiAngsuran: "",
      idAngsuran: "",

      // end
    };
  },

  beforeMount() {
    this.getKavling();
    this.getMarketing();

    this.lastPathParams = window.location.pathname.split("/").pop();

    if (this.lastPathParams != "form") {
      this.getIdData();
      this.titleForm = "Edit Kepemilikan";
    } else {
      this.titleForm = "Formulir Pembelian Blok";
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    submitValidate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.lastPathParams === "form") {
            return new Promise(() => {
              axios
                .post("v1/ownership", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Kepemilikan Berhasil`,
                    },
                  });
                  this.$router.push("/kepemilikan");
                  this.$refs["ref-form-data-kavling"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/ownership/" + this.$route.params.id, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Kepemilikan Berhasil`,
                    },
                  });
                  this.$router.push("/kepemilikan");
                  this.$refs["ref-form-data-kavling"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          }
        }
      });
    },

    getIdData() {
      return new Promise(() => {
        axios
          .get("v1/ownership/" + this.$route.params.id)
          .then((res) => {
            this.id_ownership = res.data._id;

            this.formDetail.kavling_name = res.data.kavling_name;
            this.formDetail.block_name = res.data.block_name;

            this.formSubmit.kavling_id = res.data.kavling_id;
            this.formSubmit.block_id_list = res.data.block_id_list;
            this.formSubmit.purchase_date = res.data.purchase_date;
            this.formSubmit.due_date = res.data.due_date;
            this.formSubmit.down_payment_amount = res.data.down_payment_amount;
            this.formSubmit.name = res.data.name;
            this.formSubmit.phone = res.data.phone;
            this.formSubmit.address = res.data.address;
            this.formSubmit.identity_number = res.data.identity_number;
            this.formSubmit.profession = res.data.profession;
            this.formSubmit.nationality = res.data.nationality;
            this.formSubmit.marital_status = res.data.marital_status;
            this.formSubmit.date_of_birth = res.data.date_of_birth;
            this.formSubmit.place_of_birth = res.data.place_of_birth;
            this.formSubmit.note = res.data.note;
            this.formSubmit.marketing_id = res.data.marketing_id;
            this.formSubmit.total_price = res.data.total_price;

            this.total_paid = res.data.total_paid;
            this.total_unpaid = res.data.total_unpaid;
            this.block_name = res.data.block_name;
            this.kavling_name = res.data.kavling_name;

            this.rows = res.data.payment_history;

            this.handleInputHarga();
            this.handleInputPrice();

            this.getBlok();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    showModalTambah() {
      this.formPaymentHistory.payment_amount = "";
      this.formPaymentHistory.payment_date = "";
      this.formPaymentHistory.note = "";

      this.$refs["ref-form-angsuran"].show();
      this.aksiAngsuran = "tambah";
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get("v1/ownership/" + this.id_ownership + "/" + props.row.id)
          .then((res) => {
            this.idAngsuran = res.data.id;

            this.formPaymentHistory.payment_amount = res.data.payment_amount;
            this.formPaymentHistory.payment_date = res.data.payment_date.substr(
              0,
              10
            );
            this.formPaymentHistory.note = res.data.note;
            this.handleInputPayment();

            this.aksiAngsuran = "ubah";
            this.$refs["ref-form-angsuran"].show();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    submitFormAngsuran() {
      this.$refs.simpleRulesAngsuran.validate().then((success) => {
        if (success) {
          if (this.aksiAngsuran === "tambah") {
            return new Promise(() => {
              axios
                .post(
                  "v1/ownership/" + this.id_ownership,
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Angsuran Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-angsuran"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put(
                  "v1/ownership/" + this.id_ownership + "/" + this.idAngsuran,
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Angsuran Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-angsuran"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          }
        }
      });
    },

    kembaliModalForm() {
      this.$refs["ref-form-angsuran"].hide();
    },

    getKavling() {
      return new Promise(() => {
        axios.get("v1/kavling/combo").then((res) => {
          this.kavlingOptions = res.data;
        });
      });
    },

    pilihKavling(event) {
      this.formSubmit.block_id_list = [];
      this.formSubmit.kavling_id = event;
      this.getBlok();
    },

    pilihBlok(event) {
      this.formSubmit.block_id_list = event;

      return new Promise(() => {
        axios
          .post("v1/block/price/check", this.formSubmit.block_id_list)
          .then((res) => {
            this.formSubmit.total_price = res.data;
            this.handleInputPrice();
          });
      });
    },

    getBlok() {
      return new Promise(() => {
        axios
          .get("v1/block/combo/" + this.formSubmit.kavling_id)
          .then((res) => {
            this.blokOptions = res.data;
          });
      });
    },

    pilihMarketing(event) {
      this.formSubmit.marketing_id = event;
    },

    getMarketing() {
      return new Promise(() => {
        axios.get("v1/employee/combo/marketing").then((res) => {
          this.marketingOptions = res.data;
        });
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang sudah terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/ownership/" + this.id_ownership + "/" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Blok Berhasil`,
              },
            });
            this.getIdData();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    downloadKwitansi(props) {
      window.open(
        "https://api-bsgproperti.mrizkyff.com/v1/ownership/receipt/" +
          this.$route.params.id +
          "/" +
          props.row.id
      );
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.down_payment_amount);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputPrice() {
      this.totalPrice2 = this.format2(this.formSubmit.total_price);
      this.$emit("input", (this.totalPrice2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputPayment() {
      this.paymentAmount2 = this.format2(this.formPaymentHistory.payment_amount);
      this.$emit("input", (this.paymentAmount2 + "").replace(/[^0-9]/g, ""));
    },

    backButton() {
      this.$router.push("/kepemilikan");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
